function setInputFilter(textbox, inputFilter) {
    ["input", "keydown", "keyup", "mousedown", "mouseup", "select", "contextmenu", "drop"].forEach(function (event) {
        textbox.addEventListener(event, function () {
            if (inputFilter(this.value)) {
                this.oldValue = this.value;
                this.oldSelectionStart = this.selectionStart;
                this.oldSelectionEnd = this.selectionEnd;
            } else if (this.hasOwnProperty("oldValue")) {
                this.type = 'text';
                this.value = this.oldValue;
                this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
                this.type = 'number';
            } else {
                this.value = "";
            }
        });
    });
}

if (document.getElementById("PriceAndActions")) {
    document.getElementById("PriceAndActions").addEventListener('contentLoaded', function (e) {

        var x = document.querySelector("input[type='number']");
        setInputFilter(x, function (value) {
            return /^-?\d*$/.test(value);
        })
    })
}

if (document.getElementById("productList")) {
    document.getElementById("productList").addEventListener('contentLoaded', function (e) {

        var x = document.querySelectorAll("input[type='number']");
        var j;

        for (j = 0; j < x.length; j++) {
            setInputFilter(x[j], function (value) {
                return /^-?\d*$/.test(value);
            })
        }

        document.getElementById("ProductsContainer").addEventListener('updateTemplate', function (e) {
            if (document.getElementById("ProductsContainer")) {

                var x = document.querySelectorAll("input[type='number']");
                var j;

                for (j = 0; j < x.length; j++) {
                    setInputFilter(x[j], function (value) {
                        return /^-?\d*$/.test(value);
                    })
                }
            }
        })
    })
}
if (document.getElementById("Cart")) {
    document.getElementById("Cart").addEventListener('contentLoaded', function (e) {

        var x = document.querySelectorAll("input[type='number']");
        var j;

        for (j = 0; j < x.length; j++) {
            setInputFilter(x[j], function (value) {
                return /^-?\d*$/.test(value);
            })
        }
    })
}